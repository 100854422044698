// Import just what we need
import $ from 'jquery';
import Popper from 'popper.js';

// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
// import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';
import './scss/style.scss';

// add active class to menu when navigated to


const menu = document.querySelector('.main-nav')
const menuItem = menu.querySelectorAll('li');
const menuItemLink = menu.querySelectorAll('a');

for (let i = 0; i < menuItem.length; i++){
	if(menuItemLink[i].href === location.href){
		menuItem[i].classList.add('active');
		menuItemLink[i].innerHTML += `<span class="sr-only">(current)</span>`;
		// console.log('its active')
	}	
}